import { useEffect, useState } from 'react';
import redHeart from '../../assets/icons/redHeart.svg';
import blackHeart from '../../assets/icons/blackHeart.svg';
import { ToastContainer, ToastPosition } from 'react-toastify';
import {
  handleFavoriteProp,
  isPropInFavorites,
} from '../../utils/FavoriteProperties';
import { ToastConfig } from '../../utils/ToastConfig';
import {
  PriceProps,
  Property,
  PropertyBlock,
  TPropertyTitle,
} from '../../utils/Interfaces';
import translate from '../../components/languages';
import './PropertyBlockV2.scss';
import { Popover, Typography } from '@mui/material';
import persons from '../../assets/icons/persons.svg';
import bath from '../../assets/icons/bath.svg';
import forniture from '../../assets/icons/forniture.svg';
import {
  prepareSubTitle,
  prepareTitle,
} from '../../utils/carousel/PropertyCard/CustomPropertyCard';
import { IProperty } from '../../utils/interfaces/hostfully.property.interface';
import { useHistory } from 'react-router-dom';
import useQuote from '../../utils/hooks/useQuote';
import { SkeletonsContainer } from '../PropertySkeleton/SkeletonsContainer/SkeletonsContainer';
import { HELPER_TSX } from '../../utils/HelperTSX';
import Helper from '../../utils/Helper';
import { GalleryAjax } from '../../utils/images/Gallery';
import { SpinnerLoading } from '../../components/GmapLoading';

export const PropertyBlockV2 = ({ property, filters }: PropertyBlock) => {
  let history = useHistory();
  const [isFav, setIsFav] = useState<boolean>(false);

  useEffect(() => {
    setIsFav(isPropInFavorites(property.uid));
  }, []);

  const handleHearts = (property: IProperty) => {
    setIsFav(!isFav);
    handleFavoriteProp(property);
    if (!isFav) ToastConfig(`${translate.t('propertyAdded')}`, 'success');
    else ToastConfig(`${translate.t('propertyRemoved')}`, 'error');
  };

  function sendToCheckout(property: IProperty) {
    history.push('/checkout?propertyId=' + property.uid, filters);
  }

  const title = property?.descriptions.length
    ? property?.descriptions[0]?.name
    : property?.name;

  return (
    <div className="col-12 container-depart-card no-padding">
      <CustomToastContainer />

      <div className="fav-icon-container">
        <img
          src={!isFav ? blackHeart : redHeart}
          className="fav-icon"
          onClick={() => handleHearts(property)}
        />
      </div>

      <div className="welcome-carousel-container">
        <GalleryAjax property={property} maxPicsByProp={20} />
      </div>
      <div className="col-sm-12 propertyListInfoBlock pl-3 pr-3">
        <a
          href={HELPER_TSX.prepareRouteCheckout(property, filters)}
          target="_blank"
          rel="noreferrer"
        >
          <PropertyTitle title={title} />

          <div>
            <div className="d-flex justify-content-between">
              <PropertyBlockPrice
                uid={property.uid}
                checkIn={filters.checkInDate}
                checkOut={filters.checkOutDate}
                guestNumber={filters.minimumGuests}
              />
              <GuestBedAndBath {...property} />
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

const PropertyTitle = ({ title }: TPropertyTitle) => {
  const [titleMod] = useState(prepareTitle(title));
  const [subTitleMod] = useState(prepareSubTitle(title));
  return (
    <div className="title-prop-list-cont">
      <span className="property-name2" style={{ padding: '0px !important' }}>
        {titleMod}
      </span>
      <span
        className="property-subTitle2"
        style={{ padding: '0px !important' }}
      >
        {subTitleMod}
      </span>
    </div>
  );
};

const GuestBedAndBath = (property: IProperty) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className="d-flex depart-location">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={persons} className="icon-prop-card" />
          <span className="icon-prop-desc">
            x{property.availability.baseGuests}
          </span>
        </div>
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <img src={forniture} className="icon-prop-card" />
          <span className="icon-prop-desc">x{property.beds}</span>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Typography sx={{ p: 2 }}>
              {Helper.showBedTypologies(property.beds)}
            </Typography>
          </Popover>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={bath} className="icon-prop-card" />
          <span className="icon-prop-desc">
            x{Math.ceil(parseInt(property.bathrooms))}
          </span>
        </div>
      </div>
    </div>
  );
};

const LocationAndType = (property: Property) => {
  return (
    <div className="depart-location">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="small-icon material-symbols-outlined"
          style={{ fontSize: '20px', marginRight: '5px' }}
        >
          location_on
        </span>
        <span className="">{property.city}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="mr-2">{property.type}</span>
        <span className="material-symbols-outlined">apartment</span>
      </div>
    </div>
  );
};

function PropertyBlockPrice({
  uid,
  checkIn,
  checkOut,
  guestNumber,
}: PriceProps) {
  const { quote, loading } = useQuote({ uid, checkIn, checkOut, guestNumber });

  if (loading) {
    return (
      <div className="spinner-container">
        <SpinnerLoading />
      </div>
    );
  }

  const cantDaysToBook = Helper.dateDiff(checkIn, checkOut);

  if (quote && cantDaysToBook) {
    let dailyPriceCalculated = Math.ceil(
      quote.totalWithoutTaxes / cantDaysToBook,
    );
    let showLineTrhoughPrice = dailyPriceCalculated < quote.dailyPrice;

    return (
      <div className="" style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          {showLineTrhoughPrice ? (
            <span style={{ textDecoration: 'line-through' }}>
              {quote.currency} {quote.dailyPrice}&nbsp;
            </span>
          ) : null}
          <span className="currency-precio-prop ">{quote.currency}</span>
          <span className="rate-precio-prop mr-1">
            &nbsp;{dailyPriceCalculated}
          </span>
          <span
            className="ml-1 mr-2"
            style={{ fontSize: '12px', color: '#A9A9A9' }}
          >{`/ ${translate.t('rentalNight').toLowerCase()}`}</span>
          <br />
          <span className="fs-xs">{`${quote.currency} ${Math.round(
            quote.amount,
          )} total ${translate.t('discount-price')}`}</span>
        </div>
      </div>
    );
  } else if (quote) {
    return (
      <div className="d-flex justify-content-start flex-wrap align-items-center">
        <span className="rate-precio-prop mr-1">
          {translate.t('fromUSD').toString()}{' '}
          <span style={{ fontSize: '20px' }}>
            {Math.ceil(quote.dailyPrice)}
          </span>
        </span>
        <span
          className="ml-1 mr-1"
          style={{ fontSize: '12px', color: '#A9A9A9' }}
        >{`/ ${translate.t('rentalNight').toLowerCase()}`}</span>
      </div>
    );
  }
  return (
    <div>
      <span className="rate-precio-prop mr-1">
        {translate.t('fromUSD').toString()} ACA DAILY RATE
      </span>
      <span
        className="ml-1 mr-1"
        style={{ fontSize: '12px', color: '#A9A9A9' }}
      >{`/${translate.t('rentalNight').toLowerCase()}`}</span>
    </div>
  );
}

interface CustomToastContainerProps {
  position?: ToastPosition;
  autoClose?: number;
  hideProgressBar?: boolean;
  newestOnTop?: boolean;
  closeOnClick?: boolean;
  rtl?: boolean;
  pauseOnFocusLoss?: boolean;
  draggable?: boolean;
  pauseOnHover?: boolean;
  theme?: 'light' | 'dark';
}

const CustomToastContainer: React.FC<CustomToastContainerProps> = ({
  position = 'bottom-center',
  autoClose = 5000,
  hideProgressBar = false,
  newestOnTop = false,
  closeOnClick = true,
  rtl = false,
  pauseOnFocusLoss = true,
  draggable = true,
  pauseOnHover = true,
  theme = 'light',
}) => (
  <ToastContainer
    position={position}
    autoClose={autoClose}
    hideProgressBar={hideProgressBar}
    newestOnTop={newestOnTop}
    closeOnClick={closeOnClick}
    rtl={rtl}
    pauseOnFocusLoss={pauseOnFocusLoss}
    draggable={draggable}
    pauseOnHover={pauseOnHover}
    theme={theme}
  />
);
