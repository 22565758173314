import React, { useEffect, useState } from 'react';
import translate from '../../components/languages';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ModalContact from '../../components/ContactModal';
import {
  createPhoneLink,
  createWhatsappLink,
  getWhatsappNumber,
} from '../../components/Footer';
import whatsapp from '../../assets/icons/whatsapp.svg';
import phone from '../../assets/icons/phone-circle-fill.svg';

// Define a type for the props
type HeaderContactProps = {
  show: boolean;
};

export default function HeaderContact({ show }: HeaderContactProps) {
  const history = useHistory();
  const [showModalState, setShow] = useState(show);

  const [modalContact, setModalContactShow] = useState(false);

  const setOffModalParent = () => {
    setModalContactShow(false);
  };
  const openModalContact = () => {
    setModalContactShow(true);
  };

  const goToFAQ = () => {
    history.push('/faqs');
  };

  const preventShowModal = () => {
    setShow(false);
  };
  useEffect(() => {
    setShow(show);
  }, [show]);

  function goToPropertyList() {
    history.push('/properties');
  }

  return (
    <>
      <ModalContact show={modalContact} setOffModalParent={setOffModalParent} />

      <Modal
        show={showModalState}
        onHide={() => preventShowModal()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" "
      >
        <Modal.Header closeButton={showModalState}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            {String(translate.t('headerContact.modalTitle'))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Row>
              <div className="col-12">
                <p>{String(translate.t('headerContact.body'))}</p>

                {/* Actions */}
                <Row>
                  <div className="col-12">
                    <img
                      src={phone}
                      className="mr-2"
                      style={{ width: 'auto', height: '2em' }}
                      alt="Phone Icon"
                    />
                    <a href={createPhoneLink()}>
                      {String(translate.t('headerContact.callUs'))}
                      {getWhatsappNumber()}
                    </a>
                  </div>

                  <div className="col-12">
                    <a
                      href={createWhatsappLink()}
                      className=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={whatsapp}
                        className="mr-2"
                        style={{ width: 'auto', height: '2em' }}
                        alt="WhatsApp Icon"
                      />
                      {String(translate.t('headerContact.whatsapp'))}
                    </a>
                  </div>
                </Row>

                {/* Buttons section */}
                <Row className="mt-4">
                  <div className="col-sm-12 col-md-6 mb-2">
                    <button
                      className="button w-100 uppercase"
                      style={{ height: 30 }}
                      onClick={() => {
                        goToFAQ();
                      }}
                    >
                      {String(translate.t('headerContact.btnFAQ'))}
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <button
                      className="button w-100 uppercase"
                      style={{ height: 30 }}
                      onClick={() => {
                        openModalContact();
                      }}
                    >
                      {String(translate.t('headerContact.btnContactForm'))}
                    </button>
                  </div>
                </Row>
              </div>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}
