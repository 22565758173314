import { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import right from '../../assets/11. FICHA/right.svg';
import left from '../../assets/11. FICHA/left.svg';
// import Modal from 'react-modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { isMobile } from 'react-device-detect';
import translate from '../../components/languages';

const CheckoutCarousel = ({
  items,
  pureItems,
  setPagination = false,
  infinite = true,
  showArrows = false,
  showAllBtn = true,
}) => {
  const [index, setIndex] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMobileIsOpen, setModalMobileIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const carouselFull = useRef(null);

  const responsive = {
    0: { items: 1 },
    678: { items: 3 },
    1280: { items: 3 },
    // 1350: { items: 4 },
    // 2000: { items: 5 },
    // 2500: { items: 6 },
    // 2800: { items: 7 },
  };

  const renderSlideInfo = ({ item, itemsCount }) => {
    if (setPagination) setPagination(`${item}\\${itemsCount}`);
  };

  const changeSlide = (nextIndex) => {
    const newIndex = index + nextIndex;
    if (newIndex < 0 || newIndex >= items.length) return;
    setIndex(newIndex);
    setCurrentIndex(newIndex);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <img
        src={right}
        alt="next button"
        className="alice-custom-right-btn"
        style={{ opacity: isDisabled ? '0.5' : 1 }}
      />
    );
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <img
        src={left}
        className="alice-custom-left-btn"
        alt="previous button"
        style={{
          width: '16px',
          opacity: isDisabled ? '0.5' : 1,
          position: 'absolute',
          left: '5vw',
          bottom: '-12px',
        }}
      />
    );
  };

  const pureItemsImg = pureItems.map((eachPhoto, i) => {
    return <img src={eachPhoto.originalImageUrl} alt="Loading..." key={i} />;
  });

  return (
    <div style={{ position: 'relative' }}>
      {showAllBtn && items.length > 0 && (
        <button className="view-all-propics" onClick={() => openModal(true)}>
          {translate.t('checkoutSeection.seeAllPhotos')}
        </button>
      )}

      <div className="container-top-carousel ">
        <AliceCarousel
          mouseTracking
          items={items}
          infinite={infinite}
          renderPrevButton={showArrows && renderPrevButton}
          renderNextButton={showArrows && renderNextButton}
          responsive={responsive}
          animationType="fadeout"
          disableButtonsControls={showArrows ? false : true}
          renderSlideInfo={renderSlideInfo}
          renderDotsItem={false}
          disableDotsControls={true}
          disableSlideInfo={false}
        />
      </div>
      {/* 
      <AliceCarousel
        autoHeight={true}
        autoWidth={true}
        responsive={true}

        items={pureItemsImg}
      /> */}

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        classNames={{ modal: 'fullScreenModal' }}
        styles={{
          modal: {
            maxWidth: 'none',
            width: '100%',
            margin: 0,
          },
        }}
      >
        <AliceCarousel
          // mouseTracking
          items={isMobile ? items : pureItemsImg}
          autoWidth={true}
          infinite={true}
          // controlsStrategy="responsive"
          animationType="fadeout"
          disableButtonsControls
          disableDotsControls
          ref={carouselFull}
        />

        <div
          className="d-flex justify-content-between align-items-center "
          style={{ height: '100%' }}
        >
          <button
            className="btn btn-secondary full-carousel-btn-left"
            onClick={(e) => carouselFull?.current?.slidePrev(e)}
          >
            <img
              src={left}
              alt="next button"
              className="full-carousel-arrow-left"
            />
          </button>

          <button
            className="btn btn-secondary full-carousel-btn-right"
            onClick={(e) => carouselFull?.current?.slideNext(e)}
          >
            <img
              src={right}
              alt="next button"
              className="full-carousel-arrow-right"
            />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutCarousel;
