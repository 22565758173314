import { useState } from 'react';
import {
  IReviewExported,
  IReviewExportedComment,
} from '../../utils/interfaces/hostfully.property.interface';
import translate from '../../components/languages';
import startOn from '../../assets/icons/star_on.png';
import startOff from '../../assets/icons/star_off.png';
import airbnb from '../../assets/brands/airbnb.png';

export const PropertyReviews = (allReviews: { reviews: IReviewExported }) => {
  const [limit, setLimit] = useState<number>(6);
  const [showButton, setShowButton] = useState<boolean>(true);

  const loadMore = () => {
    setLimit(30);
    setShowButton(false);
  };

  if (allReviews === undefined || !allReviews?.reviews?.reviews.length)
    return null;

  const reviews = allReviews.reviews.reviews.slice(0, limit);

  return (
    <div className="row px-3 w-100">
      <div className="mt-4 title-description checkout-mobile-container include-title">
        <p>{translate.t('reviewsCheckout').toString()}</p>
      </div>

      <div className="row">
        {reviews.map((review) => (
          <div className="review-card col-md-4 mb-5">
            <EachReview {...review} />
          </div>
        ))}

        {showButton && (
          <div className="col-3 offset-md-4 mt-5">
            <button className="view-all-propics" onClick={() => loadMore()}>
              {translate.t('reviewsBtnMore').toString()}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const EachReview = (review: IReviewExportedComment) => {
  const match = review.stars.match(/\d+/);
  const rate = match ? parseInt(match[0], 10) : 0;

  return (
    <div>
      <div className="col-12 mb-2">
        <StarRating rating={rate} />
        <span className="review-stars">
          <img src={airbnb} width={80} className="brand" alt="airbnb records" />
        </span>
      </div>
      <div className="col-12 mb-2">
        <span className="review-person">
          {review.name}, {review.date}
        </span>
      </div>
      <div className="col-12 review-body">
        <p className="text-description" title={review.body}>
          {review.body}
        </p>
      </div>
    </div>
  );
};

interface StarRatingProps {
  rating: number;
}
function StarRating({ rating }: StarRatingProps) {
  return <div className="d-inline mr-2">{getStartImages(rating)}</div>;
}

function getStartImages(value: number) {
  const maxStars = 5;
  const images = [];

  for (let i = 1; i <= maxStars; i++) {
    if (i <= value) {
      images.push(<img src={startOn} width={20} alt={`Star ${i}`} key={i} />);
    } else {
      images.push(<img src={startOff} width={20} alt={`Star ${i}`} key={i} />);
    }
  }

  return images;
}
