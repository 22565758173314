import React from 'react';
import '../components/componentStyles/FaqStyle.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import translate from '../components/languages';

// 1- ¿Cuál es el proceso para reservar un departamento por la web?
// Una vez que ingresa a nuestra web welcome2ba.com, podrá realizar una búsqueda de alojamiento según las fechas de estadía, zonas y cantidad de huéspedes. Luego de analizar las opciones que el listado le muestra, puede elegir una y reservarla a través de la web con los diferentes medios de pago disponibles. Cabe destacar que no aceptamos pagos el día de la llegada.
// También puede contactarse a nuestro WhatsApp para recibir asistencia adicional: +54 9 11 2522-9682.
// What is the process for booking an apartment on the website?
// Once you visit our website welcome2ba.com, you can search for apartments based on your stay dates, preferred areas, and number of guests. After reviewing the options listed, you can select one and book it through the website using the various payment methods available. Please note that we do not accept payments on the day of arrival.
// You can also contact us via WhatsApp for further assistance: +54 9 11 2522-9682.

// 2. Cuales son las formas de pago aceptadas?
// Aceptamos tarjeta de crédito, transferencia bancaria local e internacional, criptomonedas, y pagos en efectivo en nuestras oficinas.
// What are the payment methods?
// We accept credit cards, local and international bank transfers, cryptocurrencies, and cash payments at our offices.
// 3. ¿Hay que dejar algún depósito en garantía al alquilar?
// Sí, en todos los casos se debe dejar una garantía, la cual es reembolsable al momento del check-out. Esta garantía puede ser proporcionada mediante tarjeta de crédito o, en algunos casos, en efectivo.
// Is a security deposit required?
// Yes, in all cases a security deposit is required, which is refundable upon check-out. The deposit can be provided via credit card or, in some cases, in cash.
// 4. Qué ocurre si rompo algo?
// Como los accidentes pueden ocurrir, el depósito de seguridad se utilizará para cubrir cualquier rotura o daño que pueda ocurrir durante la estadía del huésped en nuestros alojamientos.
// What happens if I break something?
// Since accidents can happen, the security deposit will be used to cover any breakages or damages that may occur during the guest's stay in our accommodations.
// 5- ¿Hay cargos extras por consumo de gas, electricidad, expensas o agua?
// No, todos estos servicios están incluidos en el alquiler.
// Are there extra charges for gas, electricity, maintenance fees, or water?
// No, all these services are included in the rental.
// 6-¿Cuál es el período mínimo y máximo para rentar un apartamento?
// El período mínimo de alquiler es de 3 noches, aunque algunas propiedades permiten estadías de 2 noches, dependiendo de su disponibilidad y características. No existe un período máximo de alquiler, por lo que nuestros huéspedes son bienvenidos a disfrutar de su estancia durante el tiempo que deseen.

// What is the minimum and maximum rental period for an apartment?
// The minimum rental period is 3 nights, although some properties allow stays of 2 nights depending on availability and specific features. There is no maximum rental period, so our guests are welcome to enjoy their stay for as long as they wish.
// 7-¿Hay sábanas y toallas en los departamentos?
// Sí, todos los departamentos están equipados con sábanas y toallas de primer nivel. Además, en el check-in dejamos de cortesía amenities como jabón, shampoo, crema de enjuague y papel higiénico.
// Are there sheets and towels in the apartments?
// Yes, all apartments are equipped with high-quality sheets and towels. Additionally, we provide complimentary amenities at check-in, such as soap, shampoo, conditioner, and toilet paper.
// 8- ¿Cómo es el proceso de check-in y check-out?
// El check-in en la mayoría de nuestros departamentos es autónomo. Esto significa que, una vez confirmada la reserva, le proporcionaremos acceso a nuestra app para que pueda operar automáticamente y recibir todas las instrucciones de ingreso junto con los códigos de la puerta para acceder. En algunos pocos departamentos, el check-in es presencial y un representante de nuestra empresa lo estará esperando en el departamento a una hora acordada. En cuanto al check-out, es muy sencillo: si hay llaves físicas, puede dejarlas dentro del departamento o en nuestros lockers ubicados en diferentes zonas de la ciudad.
// What is the check-in and check-out process?
// Check-in at most of our apartments is self-service. This means that, once the reservation is confirmed, we will provide you with access to our app, which will guide you through the check-in process and provide you with door codes. For a few apartments, check-in is in person, and a representative from our company will meet you at the apartment at a scheduled time. As for check-out, it is very simple: if there are physical keys, you can leave them inside the apartment or in our lockers located in various areas of the city.
// 9. ¿A qué hora puedo ingresar al apartamento? ¿A que hora puedo retirarme?
// Los horarios de ingreso y salida están estipulados en los anuncios y se muestran al momento de realizar la reserva. En algunos casos, el horario de entrada es a partir de las 2 PM y en otros casos, el horario es a partir de las 4 PM. El horario de salida es a las 10 AM. De ser requerido early check o late check out, estos están sujetos a disponibilidad.
// What time can I check into the apartment?
// Check-in and check-out times are specified in the listings and are shown at the time of booking. In some cases, check-in is from 2 PM, while in others it is from 4 PM. Check-out time is 10 AM. Early check-in and late check-out requests are subject to availability.
// 10. Sus departamentos son pet friendly?
// No todos nuestros departamentos son pet friendly. Por favor, consulte la descripción de cada propiedad para verificar si acepta mascotas o contáctenos para obtener más información sobre opciones específicas.
// Are your apartments pet friendly?
// Not all of our apartments are pet friendly. Please check the description of each property to see if pets are allowed, or contact us for more information on specific options.

function FaqScreen() {
  return (
    <div>
      <NavBar />

      <Faqs />

      <Footer />
    </div>
  );
}

export default FaqScreen;

const faqs = [
  {
    question: translate.t('FAQ.1.question'),
    body: translate.t('FAQ.1.body'),
  },
  {
    question: translate.t('FAQ.2.question'),
    body: translate.t('FAQ.2.body'),
  },
  {
    question: translate.t('FAQ.3.question'),
    body: translate.t('FAQ.3.body'),
  },
  {
    question: translate.t('FAQ.4.question'),
    body: translate.t('FAQ.4.body'),
  },
  {
    question: translate.t('FAQ.5.question'),
    body: translate.t('FAQ.5.body'),
  },
  {
    question: translate.t('FAQ.6.question'),
    body: translate.t('FAQ.6.body'),
  },
  {
    question: translate.t('FAQ.7.question'),
    body: translate.t('FAQ.7.body'),
  },
  {
    question: translate.t('FAQ.8.question'),
    body: translate.t('FAQ.8.body'),
  },
  {
    question: translate.t('FAQ.9.question'),
    body: translate.t('FAQ.9.body'),
  },
  {
    question: translate.t('FAQ.10.question'),
    body: translate.t('FAQ.10.body'),
  },
];

function Faqs() {
  return (
    <div className="container-lg">
      <div className="container">
        <div className="text-faq margins">
          {faqs.map((eachFAQ, k) => (
            <div>
              <p>
                <strong>
                  {k + 1}. {eachFAQ.question}
                </strong>
                <br />
                {renderWithLineBreaks(eachFAQ.body)}
              </p>
              <div className="linea-card-faq"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function renderWithLineBreaks(text) {
  // Split the text by the <br /> tag and map each part to a React fragment
  return text.split('<br />').map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {index < text.split('<br />').length - 1 && <br />}
    </React.Fragment>
  ));
}
