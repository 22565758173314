import { useEffect, useState } from 'react';
import { PropertyService } from '../services/property.service';
import {
  IProperty,
  TPropertyCustomAmenitie,
  TPropertyDescription,
  TPropertyPhoto,
} from '../interfaces/hostfully.property.interface';

import { TRecentParams } from '../filter/FilterContainer';

export default function useProperty(filters: TRecentParams) {
  const [description, setDescription] = useState<TPropertyDescription>();
  const [photos, setPhotos] = useState<TPropertyPhoto[]>([]);
  const [roomPhotos, setRoomPhotos] = useState<TPropertyPhoto[]>([]);
  const [property, setProperty] = useState<IProperty>();
  const [customAmenities, setCustomAmenities] = useState<
    TPropertyCustomAmenitie[]
  >([]);

  const [reco360, setReco360] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const lang = localStorage.getItem('country') ?? 'es';
    filters.language = lang;

    PropertyService.getProperties(filters).then((properties) => {
      if (properties.rows.length) {
        const property = properties.rows[0];
        console.log('post fetching', property);
        setProperty(property);
        setDescription(property.descriptions[0]);
        setCustomAmenities(property.customAmenities);
        setReco360(getReco360(property.customAmenities));
        setPhotos(property.photos);

        setRoomPhotos(
          property.photos.filter((each) => each.airbnbRoomId != null),
        );
      }
      setLoading(false);
    });
  }, [filters.uid]);

  return {
    loading,
    property,
    photos,
    description,
    customAmenities,
    reco360,
    roomPhotos,
  };
}

function getReco360(customAmenities: TPropertyCustomAmenitie[]) {
  let filtered = customAmenities.filter(
    (each) => each.name.search('https://') !== -1,
  );
  return filtered.length ? filtered[0].name : null;
}
